// Inside AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const AuthContext = createContext();

// Create a provider component
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    // Check if token exists and update the user state accordingly
    if (token) {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      setUser(storedUser);
    }
  }, [token]);

  const login = (userData) => {
    setUser(userData.user);
    setToken(userData.token);
    localStorage.setItem('token', userData.token);
    localStorage.setItem('adminAsCustomer', (userData.admin_as_customer == true ? 'yes' : 'no'));
    localStorage.setItem('user', JSON.stringify(userData.user));
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('adminAsCustomer');
    localStorage.removeItem('user');
  };

  const updateProfile = (userData) => {

    console.log(user);

    setUser(userData.user);
    localStorage.setItem('user', JSON.stringify(userData.user));
  };

  const isAuthenticated = !!token; // Check if a token exists

  return (
    <AuthContext.Provider value={{ user, token, login, logout, isAuthenticated, updateProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to access the context
const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
