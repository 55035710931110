export const API_URL =
  process.env.REACT_APP_API_URL || "https://renewmytags.com/thor/api";
export const ROUTING_NUMBER_API_URL =
  "https://routing-number-api.fly.dev/banks";
export const PAYMENT_GATEWAY_DEVICEID =
  process.env.REACT_APP_PAYMENT_GATEWAY_DEVICEID || "55600971480002";
export const REACT_APP_DOMAIN_NAME = "vehicle";

export const ACH_PRICE = process.env.REACT_APP_ACH_PRICE || 5.00;
export const DEBIT_CARD_PRICE = process.env.REACT_APP_DEBIT_CARD_PRICE || 5.50;
export const CREDIT_CARD_PRICE = process.env.REACT_APP_CREDIT_CARD_PRICE || 6.50;

export const ACH_SERVICE_FEE = process.env.REACT_APP_ACH_SERVICE_FEE || 0;
export const DEBIT_CARD_SERVICE_FEE = process.env.REACT_APP_DEBIT_CARD_SERVICE_FEE || 0;
export const CREDIT_CARD_SERVICE_FEE = process.env.REACT_APP_CREDIT_CARD_SERVICE_FEE || 1.75;

export const TSEP_HOST =
  process.env.REACT_TSEP_HOST || "https://gateway.transit-pass.com";
export const GTM_ID = process.env.REACT_GTM_ID || "GTM-NMWLP8HQ";
