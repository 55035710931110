import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faUserCircle,
    faArrowRight,
    faUser,
    faLock,
    faKey,
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import './LoginPopup.css';
import { API_URL } from '../../config';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

const LoginPopup = ({ isOpen, onRequestClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [mode, setMode] = useState('login'); // 'login' or 'resetPassword'
    const { login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
  
    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: 'Please fill in both email and password fields.',
            });
            return;
        }

        if (!isValidEmail(email)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a valid email address.',
            });
            return;
        }

        try {
            const response = await fetch(API_URL + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                login(data);
                onRequestClose();
                navigate('/account');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: data.message || 'Login failed',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An error occurred. Please try again later.',
            });
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!isValidEmail(resetEmail)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a valid email address.',
            });
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(API_URL + '/password/email', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: resetEmail }),
        });

        const data = await response.json();

        if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: 'Reset Password Email Sent',
                text: data.message || 'Check your email for reset instructions.',
            });
            setMode('login'); // Switch back to login mode after sending reset email

            setLoading(false);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: data.message || 'Failed to send reset email',
            });
            setLoading(false);
        }
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'An error occurred. Please try again later.',
        });
        setLoading(false);
    }
};

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="login-popup"
        overlayClassName="overlay"
    >
        <div className="login-content">
            <div className="login-icon">
                <FontAwesomeIcon icon={mode === 'login' ? faUserCircle : faKey} />
            </div>
            {loading ? <Loading text="Sending password reset link..." /> : ''}
            <button className="close-button" onClick={onRequestClose}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2>{mode === 'login' ? 'Sign In' : 'Reset Password'}</h2>

            {mode === 'login' ? (
                <form onSubmit={handleLogin}>
                    <div className="login-input">
                        <FontAwesomeIcon icon={faUser} />
                        <input
                            id="loginEmail"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="login-input">
                        <FontAwesomeIcon icon={faLock} />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="clear-both pt-10">
                        <div className="pull-left">
                            <button type="submit" className="btn btn-primary login-button">
                                Login <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                        <div className="pull-right">
                            <button onClick={() => setMode('resetPassword')} className="btn-link">Forgot your password?</button>
                        </div>
                    </div>
                    
                    
                </form>
            ) : (
                <form onSubmit={handleResetPassword}>
                    <div className="login-input">
                        <FontAwesomeIcon icon={faUser} />
                        <input
                            id="resetEmail"
                            type="text"
                            placeholder="Email"
                            value={resetEmail}
                            onChange={(e) => setResetEmail(e.target.value)}
                        />
                    </div>

                    <div className="clear-both pt-10">
                        <div className="pull-left">
                            <button type="submit" className="btn btn-primary login-button">
                                Reset Password <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                        <div className="pull-right">
                            <button onClick={() => setMode('login')} className="btn-link">Back to Login?</button>
                        </div>
                    </div>

                </form>
            )}

            {mode === 'login' ? (
                <p className="resetLink">
                    
                </p>
            ) : (
                <p className="resetLink">
                    
                </p>
            )}
        </div>
    </Modal>
  );
};

export default LoginPopup;
