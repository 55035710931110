import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import SignupWizard from './pages/SignupWizard';
import BillingInformation from './pages/BillingInformation';
import PaymentMethods from './pages/PaymentMethods';
import Account from './pages/Account';
import Welcome from './pages/Welcome';
import Profile from './pages/Profile';
import { AuthProvider, useAuth } from './pages/component/AuthContext';
import ResetPassword from './pages/ResetPassword';
import LoginAsCustomer from './pages/LoginAsCustomer';
import CustomPages from './pages/CustomPages';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsUse from './pages/TermsUse';
import ContactUs from './pages/ContactUs';
import HowItWorks from './pages/HowItWorks';
import Pricing from './pages/Pricing';
import TagManager from 'react-gtm-module';
import { GTM_ID } from './config';
import NotFound from './pages/NotFound';
import RenewalHistory from './pages/RenewalHistory';

const tagManagerArgs = {
  gtmId: GTM_ID,
};
TagManager.initialize(tagManagerArgs);

const PrivateRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/" replace />;
};

const PublicRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated && element.type.name != 'LoginAsCustomer' ? <Navigate to="/account" replace /> : element;
};

const App = () => {
  window.dataLayer.push({
    event: 'pageview',
  });
  return (
    <Router>
      <div className="app">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<PublicRoute element={<Home />} />} />
            <Route path="/password/reset/:token" element={<PublicRoute element={<ResetPassword />} />} />
            <Route
              path="/signup-wizard/:onboardingId"
              element={<PublicRoute element={<SignupWizard />} />}
            />
            <Route
              path="/plate-information/:onboardingId/:pageId"
              element={<PublicRoute element={<CustomPages />} />}
            />
            <Route
              path="/billing-information/:onboardingId"
              element={<PublicRoute element={<BillingInformation />} />}
            />
            <Route
              path="/terms/use"
              element={<TermsUse />}
            />
            <Route
              path="/privacy/policy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="/how/works"
              element={<HowItWorks />}
            />
            <Route
              path="/pricing"
              element={<Pricing />}
            />
            <Route
              path="/contact/us"
              element={<ContactUs />}
            />
            <Route path="/login-as-customer/:userId" element={<PublicRoute element={<LoginAsCustomer />} />} />
            <Route path="/account" element={<PrivateRoute element={<Account />} />} />
            <Route path="/welcome" element={<PrivateRoute element={<Welcome />} />} />
            <Route path="/payment-methods" element={<PrivateRoute element={<PaymentMethods />} />} />
            <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
            <Route path="/renewal-history/:plateNumber"  element={<PrivateRoute element={<RenewalHistory />} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </div>
    </Router>
  );
};

export default App;